@import '~@weareopenr/component-library/build/theme.scss';

.page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing-6;
  background: $openr-gradient;
}

.button-container {
  margin-top: $spacing-4;
}

.button {
  background-color: $blueberry;
}
